import { eachDayOfInterval, isValid } from 'date-fns'

/**
 * @param {string | Date} startDate
 * @param {string | Date} endDate
 */

export const getDatesBetweenDates = (startDate, endDate) => {
  if (!isValid(startDate) || !isValid(endDate)) return null

  return eachDayOfInterval({ start: startDate, end: endDate })
}
