import { useMutation } from '@apollo/client'
import { loader } from 'graphql.macro'

const MUTATION = loader('../../../graphql/auth/mutations/requestForgotPassword.gql')

/**
 * @typedef {{
 *  username: string
 * }} MutationVars
 *
 * @param {import('@apollo/client').MutationHookOptions<boolean, MutationVars>} [options]
 * @returns {import('@apollo/client').MutationTuple<boolean, MutationVars>}
 */
export const useRequestForgotPassword = options => useMutation(MUTATION, options)
