import { forwardRef, useState } from 'react'
import { TimePicker as MUITimePicker } from '@mui/lab'

/**
 * @type {import('react').ForwardRefRenderFunction<import('@mui/lab').TimePicker, import('@mui/lab').TimePickerProps>}
 */
const TimePickerRef = (props, ref) => {
  const [open, setOpen] = useState(false)

  return (
    <MUITimePicker
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      ref={ref}
      {...props}
      InputProps={{ onClick: () => setOpen(true), ...(props.InputProps ?? {}) }}
    />
  )
}

export const TimePicker = forwardRef(TimePickerRef)
