import { useEffect, useRef } from 'react'

/**
 * @template T
 * @function
 * @param {T} value
 * @returns {T}
 */
export const usePrevious = value => {
  const ref = useRef()

  useEffect(() => {
    ref.current = value
  }, [value])

  return ref.current
}
