import { useContext } from 'react'

import { LocaleContext } from '../context'

/**
 * @returns {import('date-fns').Locale}
 */
export function useLocale() {
  return useContext(LocaleContext)
}
