import { loader } from 'graphql.macro'
import { useQuery } from '@apollo/client'

const QUERY = loader('../../../graphql/auth/queries/identity.gql')

/**
 * @typedef {{ identity: { email: string, firstname: string, lastname: string, status: string } }} QueryResult
 * @typedef {{}} QueryVar
 *
 * @param {boolean} skip
 * @returns {import('@apollo/client').QueryResult<QueryResult, QueryVar>}
 */
export const useIdentity = skip => useQuery(QUERY, { skip })
