import { useMemo } from 'react'

/**
 * @param {Object} params
 * @param {string} params.gatheringUuid
 * @param {string} params.healthCareProfessionalFileUuid
 * @returns {URL}
 */
export const usePreviewHealthCareProfessionalFile = ({ gatheringUuid, healthCareProfessionalFileUuid }) =>
  useMemo(() => {
    const origin = window.location.origin
    const url = new URL(
      `${origin}/controller/healthCareProfessionalFile/${gatheringUuid}/${healthCareProfessionalFileUuid}`
    )

    return url
  }, [gatheringUuid, healthCareProfessionalFileUuid])
