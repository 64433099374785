import { useEffect } from 'react'

import { EnvIdMapping } from '../enum'

const ENV_MAPPING = {
  dev: `https://consent.cookiebot.com/${EnvIdMapping.dev}/cd.js`,
  uat: `https://consent.cookiebot.com/${EnvIdMapping.uat}/cd.js`,
  preprod: `https://consent.cookiebot.com/${EnvIdMapping.preprod}/cd.js`,
  production: `https://consent.cookiebot.com/${EnvIdMapping.production}/cd.js`
}

/**
 * @param {string} target
 */
export const useCookieBotPageScript = target => {
  useEffect(() => {
    const source = ENV_MAPPING[process.env.REACT_APP_NODE_ENV] || ENV_MAPPING.production

    // Remove previously included scripts
    const existingScripts = document.querySelectorAll('script') || []
    for (const script of Array.from(existingScripts)) {
      if (script.getAttribute('src') === source) script.remove()
    }

    const script = document.createElement('script')
    script.setAttribute('src', source)
    script.setAttribute('async', true)
    script.setAttribute('id', 'CookieDeclaration')
    script.setAttribute('type', 'text/javascript')
    script.setAttribute('data-culture', 'fr')

    document.getElementById(target).appendChild(script)
  }, [target])
}
