import { forwardRef, useState } from 'react'
import { DatePicker as MUIDatePicker } from '@mui/lab'

/**
 * @type {import('react').ForwardRefRenderFunction<import('@mui/lab').DatePicker, import('@mui/lab').DatePickerProps>}
 */
const DatePickerRef = (props, ref) => {
  const [open, setOpen] = useState(false)

  return (
    <MUIDatePicker
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      ref={ref}
      {...props}
      InputProps={{ onClick: () => setOpen(true), ...(props.InputProps ?? {}) }}
    />
  )
}

export const DatePicker = forwardRef(DatePickerRef)
