import { InMemoryCache } from '@apollo/client'
import { parse } from 'date-fns'

import { InvitationStatus } from '@hcp/modules/participant'

/**
 * @param {string} rawDate
 * @param {string} rawTime
 * @returns {Date}
 */
const formatRawToDate = (rawDate, rawTime) => {
  const date = new Date(rawDate)

  if (rawTime) {
    const [hour, minute] = rawTime.split(':').map(Number)
    date.setHours(hour, minute, 0, 0)
  } else {
    date.setHours(0, 0, 0, 0)
  }

  return date
}

export const inMemory = new InMemoryCache({
  typePolicies: {
    ActivityGroup: {
      fields: {
        date: {
          read(date) {
            return date ? new Date(date) : null
          }
        }
      }
    },
    ActivityParticipant: {
      keyFields: ['activityParticipantUuid']
    },
    ActivitiesSchedule: {
      fields: {
        startTime: {
          read(startTime) {
            return startTime ? parse(startTime, 'HH:mm', new Date()) : null
          }
        },
        endTime: {
          read(endTime) {
            return endTime ? parse(endTime, 'HH:mm', new Date()) : null
          }
        }
      }
    },
    BiogenLincActivity: {
      keyFields: ['activityUuid'],
      fields: {
        date: {
          read(date) {
            return date ? new Date(date) : null
          }
        },
        startHour: {
          read(startHour) {
            return startHour ? parse(startHour, 'HH:mm', new Date()) : null
          }
        },
        endHour: {
          read(endHour) {
            return endHour ? parse(endHour, 'HH:mm', new Date()) : null
          }
        }
      }
    },
    BiogenLincGathering: {
      keyFields: ['gatheringUuid'],
      fields: {
        agenda: {
          keyArgs: ['stripUnsubscribed', 'strictTimeSlots', 'entryType']
        },
        beginDate: {
          read(beginDate) {
            return beginDate ? new Date(beginDate) : null
          }
        },
        endDate: {
          read(endDate) {
            return endDate ? new Date(endDate) : null
          }
        },
        isCongress: {
          read(_, { readField }) {
            return readField('congress') !== null
          }
        },
        isHybrid: {
          read(_, { readField }) {
            return readField('isPhysical') && readField('isVirtual')
          }
        }
      }
    },
    HcpAdditionalInformation: {
      keyFields: ['hcpAdditionalInformationUuid'],
      fields: {
        planeSubscriptionExpirationDate: {
          read(date) {
            return date ? new Date(date) : null
          }
        },
        trainCardExpirationDate: {
          read(date) {
            return date ? new Date(date) : null
          }
        }
      }
    },
    HealthCareProfessional: {
      keyFields: ['healthCareProfessionalUuid']
    },
    Hosting: {
      keyFields: ['hostingUuid']
    },
    HostingConfiguration: {
      keyFields: ['hostingConfigurationUuid']
    },
    HostingNight: {
      keyFields: ['hostingNightUuid']
    },
    ManualParticipant: {
      keyFields: ['manualParticipantUuid']
    },
    OvernightStay: {
      keyFields: ['overnightStayUuid']
    },
    Participant: {
      keyFields: ['participantUuid'],
      fields: {
        canSubscribe: {
          read(_, { readField }) {
            return readField('invitationStatus') === InvitationStatus.Unanswered
          }
        },
        isConfirmed: {
          read(_, { readField }) {
            return readField('invitationStatus') === InvitationStatus.Confirmed
          }
        },
        isRefused: {
          read(_, { readField }) {
            return readField('invitationStatus') === InvitationStatus.Refused
          }
        },
        isSubscribed: {
          read(_, { readField }) {
            return readField('isConfirmed')
          }
        }
      }
    },
    TransferConfiguration: {
      keyFields: ['transferConfigurationUuid']
    },
    TransportConfiguration: {
      keyFields: ['transportConfigurationUuid']
    },
    TransportNote: {
      keyFields: ['transportNoteUuid'],
      fields: {
        noteReceivalDate: {
          read(date) {
            return date ? new Date(date) : null
          }
        }
      }
    },
    Travel: {
      keyFields: ['travelUuid'],
      fields: {
        departure: {
          read(_, { readField }) {
            const rawDate = readField('departureDate')
            const rawTime = readField('departureTime')

            return formatRawToDate(rawDate, rawTime)
          }
        },
        arrival: {
          read(_, { readField }) {
            const rawDate = readField('arrivalDate')
            const rawTime = readField('arrivalTime')

            return formatRawToDate(rawDate, rawTime)
          }
        }
      }
    },
    User: {
      keyFields: ['userUuid']
    }
  }
})
