import { Navigate, useLocation } from 'react-router-dom'

import { AppRoutes } from '@hcp/router'
import { usePrevious } from '@hcp/core'

import { useAuth } from '../hooks/useAuth'

/**
 * @typedef {{
 *  notAuth: boolean
 * }} RequireAuthProps
 */

/**
 * @type {import("react").FunctionComponent<RequireAuthProps>}
 */
export const RequireAuth = ({ children, notAuth = false }) => {
  const location = useLocation()

  const { isAuthenticated } = useAuth()

  const previousIsAuthenticated = usePrevious(isAuthenticated)
  const hasSignedOut = !isAuthenticated && previousIsAuthenticated

  if (notAuth && isAuthenticated) {
    return <Navigate to={AppRoutes.App} />
  }
  if (!notAuth && !isAuthenticated) {
    window.sessionStorage.setItem('deeplink', location.pathname)

    return <Navigate to={AppRoutes.Authentication.Login} state={{ from: hasSignedOut ? null : location }} />
  }

  return children
}
