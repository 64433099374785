import { useEffect } from 'react'

import { useAuth } from '@hcp/modules/auth'

import { IdentityContext } from '../context'
import { useIdentity } from '../hooks'

/**
 * @type {import('react').NamedExoticComponent}
 */
export const IdentityProvider = ({ children }) => {
  const { isAuthenticated } = useAuth()
  const { data, refetch } = useIdentity(!isAuthenticated)

  useEffect(() => {
    if (!isAuthenticated) return

    refetch()
  }, [isAuthenticated, refetch])

  return <IdentityContext.Provider value={isAuthenticated ? data?.identity : null}>{children}</IdentityContext.Provider>
}
