import { ReactComponent as BMLogo } from './logos/bm.svg'
import { ReactComponent as BiogenLogo } from './logos/biogen.svg'
import { ReactComponent as MagnifyingGlassIcon } from './icons/magnifying-glass.svg'
import { ReactComponent as TrainIcon } from './icons/train.svg'
import { ReactComponent as CalendarIcon } from './icons/calendar.svg'
import { ReactComponent as DownloadIcon } from './icons/download.svg'
import { ReactComponent as DownloadCircleIcon } from './icons/download-circle.svg'
import { ReactComponent as HostingIcon } from './icons/hosting-icon.svg'
import { ReactComponent as ExpenseNoteIcon } from './icons/expense-notes.svg'
import { ReactComponent as LetterIcon } from './icons/letter.svg'
import { ReactComponent as HCPComputer } from './artworks/hcp-computer.svg'
import { ReactComponent as SubscriptionSuccess } from './artworks/subscription-success.svg'
import Waves from './artworks/waves.png'
import { ReactComponent as Done } from './artworks/done.svg'
import { ReactComponent as WavesSVG } from './artworks/waves.svg'
import { ReactComponent as SearchEmpty } from './artworks/search-empty.svg'
import { ReactComponent as Confirmation } from './artworks/confirmation.svg'

export {
  MagnifyingGlassIcon,
  TrainIcon,
  CalendarIcon,
  DownloadIcon,
  HostingIcon,
  ExpenseNoteIcon,
  LetterIcon,
  DownloadCircleIcon
}

export const Artworks = { Done, HCPComputer, SearchEmpty, SubscriptionSuccess, Waves, WavesSVG, Confirmation }

export const Logos = { BM: BMLogo, Biogen: BiogenLogo }
