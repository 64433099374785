import { Box } from '@mui/system'
import { useTheme } from '@mui/material/styles'
import { useMediaQuery } from '@mui/material'

import { Artworks } from '@hcp/ux'

/**
 * @typedef {{
 *  fluid?: boolean
 *  waves?: boolean
 *  reverseWaves?: boolean
 * }} HeroProps
 *
 * @type {import("react").FunctionComponent<HeroProps>}
 */
export const Hero = ({ children, fluid = false, waves = false, reverseWaves = false }) => {
  const theme = useTheme()
  const enableWaves = useMediaQuery(theme.breakpoints.up('md'))

  return (
    <Box
      boxSizing="border-box"
      bgcolor="white"
      height={fluid ? 'auto' : ['auto', 'auto', 312]}
      width="100%"
      overflow="hidden"
    >
      <Box
        display="flex"
        width="100%"
        height="100%"
        bgcolor="primary.200"
        overflow="hidden"
        sx={{
          position: 'relative',
          '&::after': {
            zIndex: 0,
            content: enableWaves && waves ? '""' : '',
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundImage: `url(${Artworks.Waves})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
            filter: reverseWaves ? 'brightness(0) invert(1)' : ''
          }
        }}
      >
        <Box flexGrow={1} zIndex={1}>
          {children}
        </Box>
      </Box>
    </Box>
  )
}
