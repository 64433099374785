import { useMutation } from '@apollo/client'
import { loader } from 'graphql.macro'

const MUTATION = loader('../../../graphql/auth/mutations/fullSignup.gql')

/**
 * @typedef {{
 *  firstname: string
 *  lastname: string
 *  postalCode: string
 *  hcpIdentifier: string
 *  password: string
 * }} MutationVars
 *
 * @param {import('@apollo/client').MutationHookOptions<boolean, MutationVars>} [options]
 * @returns {import('@apollo/client').MutationTuple<boolean, MutationVars>}
 */
export const useFullSignup = options => useMutation(MUTATION, options)
