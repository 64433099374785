import { Suspense, lazy, useEffect } from 'react'
import { Outlet, Route, Routes } from 'react-router-dom'
import jwt from 'jsonwebtoken'

import { AuthLayoutLoader, AuthPageLayout, RequireAuth } from '@hcp/modules/auth'
import { AppBar, AppPageLayout } from '@hcp/modules/system'
import { usePrevious } from '@hcp/core'
import { getUserToken } from '@hcp/modules/auth/context'

import { AppRoutes } from '../routes'

import { RouteLoader } from './RouteLoader'

// Local authentification login page
// const LoginPage = lazy(() => import('@hcp/pages/auth/LoginPage'))
const LoginAkamaiPage = lazy(() => import('@hcp/pages/auth/LoginAkamaiPage'))
const RegisterPage = lazy(() => import('@hcp/pages/auth/RegisterPage'))
const RegisterFullPage = lazy(() => import('@hcp/pages/auth/RegisterFullPage'))
const ForgotPasswordPage = lazy(() => import('@hcp/pages/auth/ForgotPasswordPage'))
const ResetPasswordPage = lazy(() => import('@hcp/pages/auth/ResetPasswordPage'))
const UnvalidatedPage = lazy(() => import('@hcp/pages/auth/UnvalidatedPage'))

const EventsPage = lazy(() => import('@hcp/pages/events/EventsPage'))
const UpComingEventsPage = lazy(() => import('@hcp/pages/events/UpComingEventsPage'))
// const SubscribedEventsPage = lazy(() => import('@hcp/pages/events/SubscribedEventsPage'))

const EventPage = lazy(() => import('@hcp/pages/events/EventPage'))
const EventDetailsPage = lazy(() => import('@hcp/pages/events/EventDetailsPage'))
const EventTransportPage = lazy(() => import('@hcp/pages/events/EventTransportPage'))
const EventHostingPage = lazy(() => import('@hcp/pages/events/EventHostingPage'))
const EventAgendaPage = lazy(() => import('@hcp/pages/events/EventAgendaPage'))
const EventExpenseFeePage = lazy(() => import('@hcp/pages/events/EventExpenseFeePage'))
const CookiesPage = lazy(() => import('@hcp/pages/cookies/CookiesPage'))

const EventRegisterPage = lazy(() => import('@hcp/pages/event/EventRegisterPage'))

/**
 * @type {import('react').FunctionComponent}
 */
export const AppRouter = () => {
  // Test if we have a user token - if so, check that it is not expired
  // as its duration is synced with the session token (which hold the authentification)
  const userToken = getUserToken()
  const decodedUserToken = userToken ? jwt.decode(userToken) : null
  const utkExpiresAt = decodedUserToken ? new Date(decodedUserToken.eat) : null
  const isAuthenticated = utkExpiresAt ? new Date() < utkExpiresAt : false

  const previousIsAuthenticated = usePrevious(isAuthenticated)
  const hasLoggedIn = !previousIsAuthenticated && isAuthenticated

  useEffect(() => {
    if (!hasLoggedIn) return

    const deeplink = window.sessionStorage.getItem('deeplink')

    if (deeplink) {
      window.location.href = deeplink
      window.sessionStorage.removeItem('deeplink')
    }
  }, [hasLoggedIn])

  return (
    <Routes>
      <Route path={AppRoutes.App} element={<AppPageLayout />}>
        <Route
          path={AppRoutes.App}
          element={
            <RequireAuth>
              <Outlet />
            </RequireAuth>
          }
        >
          <Route
            path={AppRoutes.App}
            element={
              <Suspense fallback={<RouteLoader />}>
                <EventsPage />
              </Suspense>
            }
          >
            <Route
              path={AppRoutes.Events.UpComing}
              element={
                <Suspense fallback={<RouteLoader />}>
                  <UpComingEventsPage />
                </Suspense>
              }
            />
            {/* <Route
            path={AppRoutes.Events.MyEvents}
            element={
              <Suspense fallback={<RouteLoader />}>
                <SubscribedEventsPage />
              </Suspense>
            }
          /> */}
          </Route>
          <Route
            path={AppRoutes.Event.Details}
            element={
              <Suspense fallback={<RouteLoader />}>
                <EventPage />
              </Suspense>
            }
          >
            <Route
              index
              element={
                <Suspense fallback={<RouteLoader />}>
                  <EventDetailsPage />
                </Suspense>
              }
            />
            <Route
              path={AppRoutes.Event.Transport}
              element={
                <Suspense fallback={<RouteLoader />}>
                  <EventTransportPage />
                </Suspense>
              }
            />
            <Route
              path={AppRoutes.Event.Hosting}
              element={
                <Suspense fallback={<RouteLoader />}>
                  <EventHostingPage />
                </Suspense>
              }
            />
            <Route
              path={AppRoutes.Event.Agenda}
              element={
                <Suspense fallback={<RouteLoader />}>
                  <EventAgendaPage />
                </Suspense>
              }
            />
            <Route
              path={AppRoutes.Event.ExpenseFee}
              element={
                <Suspense fallback={<RouteLoader />}>
                  <EventExpenseFeePage />
                </Suspense>
              }
            />
          </Route>
          <Route
            path={AppRoutes.Event.Register}
            element={
              <Suspense fallback={<RouteLoader />}>
                <EventRegisterPage />
              </Suspense>
            }
          />
        </Route>
      </Route>
      <Route path={AppRoutes.Authentication.Index} element={<AuthPageLayout />}>
        <Route
          path={AppRoutes.Authentication.Login}
          element={
            <Suspense fallback={<AuthLayoutLoader />}>
              <RequireAuth notAuth>
                <LoginAkamaiPage />
              </RequireAuth>
            </Suspense>
          }
        />
        <Route
          path={AppRoutes.Authentication.Register}
          element={
            <Suspense fallback={<AuthLayoutLoader />}>
              <RequireAuth notAuth>
                <RegisterPage />
              </RequireAuth>
            </Suspense>
          }
        />
        <Route
          path={AppRoutes.Authentication.RegisterFull}
          element={
            <Suspense fallback={<AuthLayoutLoader />}>
              <RequireAuth notAuth>
                <RegisterFullPage />
              </RequireAuth>
            </Suspense>
          }
        />
        <Route
          path={AppRoutes.Authentication.ForgotPassword}
          element={
            <Suspense fallback={<AuthLayoutLoader />}>
              <RequireAuth notAuth>
                <ForgotPasswordPage />
              </RequireAuth>
            </Suspense>
          }
        />
        <Route
          path={AppRoutes.Authentication.ResetPassword}
          element={
            <Suspense fallback={<AuthLayoutLoader />}>
              <RequireAuth notAuth>
                <ResetPasswordPage />
              </RequireAuth>
            </Suspense>
          }
        />
        <Route
          path={AppRoutes.Authentication.Unvalidated}
          element={
            <Suspense fallback={<AuthLayoutLoader />}>
              <UnvalidatedPage />
            </Suspense>
          }
        />
      </Route>
      <Route path={AppRoutes.App} element={<AppPageLayout appBar={<AppBar displayLogoutButton={false} />} />}>
        <Route
          path={AppRoutes.Cookies}
          element={
            <Suspense fallback={<RouteLoader />}>
              <CookiesPage />
            </Suspense>
          }
        />
      </Route>
    </Routes>
  )
}
