import { useMemo } from 'react'

/**
 * @param {string} fileUuid
 * @returns {string}
 */
export const useFileUrlGenerator = fileUuid =>
  useMemo(() => {
    if (!fileUuid) return null

    const origin = window.location.origin

    return `${origin}/controller/file/${fileUuid}`
  }, [fileUuid])
