import { CircularProgress, Stack } from '@mui/material'

/**
 * @type {import('react').FunctionComponent}
 */
export const RouteLoader = () => (
  <Stack flexGrow={1} alignItems="center" justifyContent="center">
    <CircularProgress />
  </Stack>
)
