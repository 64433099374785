import { useCurrentHcp } from '@hcp/modules/user/hooks'

/**
 * @returns {HealthCareProfessional | undefined}
 */
export const useUser = () => {
  const { data } = useCurrentHcp()

  return data?.currentHealthCareProfessional
}
