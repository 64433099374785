import { useMutation } from '@apollo/client'
import { loader } from 'graphql.macro'

const MUTATION = loader('../../../graphql/participants/mutations/subscribeToGathering.gql')

/**
 * @typedef {{
 *  personalInformations: {
 *      lastname: string
 *      firstname: string
 *      email: string
 *      address: string
 *      mobile: string
 *      emailConsents: boolean
 *  }
 *  participation: {
 *      participates: boolean
 *      learnedSociety?: {
 *          member: boolean
 *          memberId?: string
 *      }
 *      type?: ParticipationType
 *  }
 *  agenda?: {
 *      activities: { date: Date, interval: Date, comment?: string, activityUuid: string}[]
 *  }
 *  transport?: {
 *    birthdate: string | null
 *    trainCardName: string | null
 *    trainCardNumber: string | null
 *    trainCardExpirationDate: Date | null
 *    planeSubscriptionName: string | null
 *    planeSubscriptionNumber: string | null
 *    planeSubscriptionExpirationDate: Date | null
 *    preferenceModes: TransportMode[]
 *    departureDate: Date
 *    departureHour: Date | null
 *    departurePlace: string
 *    departureArrivalPlace: string | null
 *    returnDate: Date
 *    returnHour: Date | null
 *    returnPlace: string | null
 *    returnArrivalPlace: string
 *  }
 *  hosting?: {
 *      overnightStays?: Date[]
 *      transferNeeded?: boolean
 *  }
 * }} BiogenLincSubscriptionInput
 */

/**
 * @typedef {{
 *  gatheringUuid: string
 *  subscription: BiogenLincSubscriptionInput
 * }} MutationVars
 *
 * @param {string} gatheringUuid
 * @returns {import('@apollo/client').MutationTuple<any, MutationVars>}
 */
export const useSubscribeToGathering = gatheringUuid => useMutation(MUTATION, { variables: { gatheringUuid } })
