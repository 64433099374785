import { memo } from 'react'
import { Card, Typography } from '@mui/material'
import { Box } from '@mui/system'

import { CalendarIcon, DownloadIcon } from '../assets'

/**
 * @typedef {{
 *  label: string
 *  subText?: string
 *  onClick: function(): void
 * }} FilePreviewProps
 *
 * @type {import('react').NamedExoticComponent<FilePreviewProps>}
 */
export const FilePreview = memo(({ label, subText, onClick }) => (
  <Card variant="outlined" onClick={onClick} sx={{ cursor: 'pointer' }}>
    <Box display="flex" alignItems="center" py={2} pl={2} pr={3}>
      <Box
        height={48}
        width={48}
        bgcolor="primary.light200"
        borderRadius="100%"
        display="flex"
        alignItems="center"
        justifyContent="center"
        sx={{ aspectRatio: '1' }}
      >
        <CalendarIcon height={30} width={20} />
      </Box>
      <Typography ml={3} fontWeight={700} color="blue.greyish">
        {label}
      </Typography>
      {subText && <Typography color="primary.greyish">{subText}</Typography>}
      <Box ml={3} flexGrow={1} display="flex" alignItems="center" justifyContent="flex-end">
        <DownloadIcon height={20} />
      </Box>
    </Box>
  </Card>
))
