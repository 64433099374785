import { Outlet } from 'react-router-dom'

import { AppBar } from '@hcp/modules/system'

/**
 * @typedef {{
 *  appBar?: import('react').ReactNode
 * }} AppPageLayoutProps
 *
 * @type {import('react').FunctionComponent<AppPageLayoutProps>}
 */

export const AppPageLayout = ({ appBar }) => {
  return (
    <>
      {appBar || <AppBar />}
      <Outlet />
    </>
  )
}
