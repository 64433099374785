import { useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import { Dialog, useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { Box } from '@mui/system'

import { useSetTranslationContext } from '@hcp/modules/translations'
import { Artworks } from '@hcp/ux'

/**
 * @type {import('react').FunctionComponent<import('react-router').RouteProps>}
 */
export const AuthPageLayout = () => {
  const theme = useTheme()
  const breakpointDownSm = useMediaQuery(theme.breakpoints.down('sm'))

  const setTranslationContext = useSetTranslationContext()
  useEffect(() => setTranslationContext('auth'))

  return (
    <Box bgcolor="primary.200" flex={1}>
      <Box position="absolute" top={0} bottom={0} left={0} right={0} overflow="hidden">
        <Artworks.WavesSVG height="100%" />
      </Box>

      <Dialog
        open
        hideBackdrop
        maxWidth="sm"
        fullWidth
        fullScreen={breakpointDownSm}
        scroll="body"
        PaperProps={{ elevation: 0, sx: { padding: '0px !important' } }}
      >
        <Outlet />
      </Dialog>
    </Box>
  )
}
