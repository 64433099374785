import { useCallback, useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { AppRoutes } from '@hcp/router'

import {
  AccountHelperContext,
  AccountStateContext,
  AUTH_INITIAL_TOKEN,
  getExistingToken,
  persistToken
} from '../context'

/** @type {import('../context').Account | null} */
const initialAccount = AUTH_INITIAL_TOKEN ? { token: AUTH_INITIAL_TOKEN } : null

/**
 * @type {import("react").FunctionComponent}
 */
export const AuthProvider = ({ children }) => {
  /** @type {[import('../context').Account | null, import('react').Dispatch<import('react').SetStateAction<import('../context').Account | null>>]} */
  const [account, setAccount] = useState(initialAccount)

  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    if (account && location.state?.from) {
      navigate(location.state?.from, { replace: true })
    }
  }, [account, location.state?.from, navigate])

  // listen redirection from backend for logout to clean the local cookie
  useEffect(() => {
    // listen on this route due to backend redirecting to it after logout
    if (location.pathname === AppRoutes.Authentication.Login) {
      const savedToken = getExistingToken()

      if (!savedToken) {
        persistToken(null)
        setAccount(null)
      }
    }
  }, [location.pathname])

  /** @type {import('../context').SetAuthenticatedFn} */
  const setAuthenticated = useCallback(token => {
    if (token) {
      setAccount({ token })
    } else {
      window.location = '/controller/logout'
    }
  }, [])

  /** @type {import('../context').AccountStateContextValue} */
  const accountStateContextValue = useMemo(
    () => ({
      isAuthenticated: !!account
    }),
    [account]
  )

  /** @type {import('../context').AccountHelperContextValue} */
  const accountHelperContextValue = useMemo(
    () => ({
      setAuthenticated
    }),
    [setAuthenticated]
  )

  return (
    <AccountHelperContext.Provider value={accountHelperContextValue}>
      <AccountStateContext.Provider value={accountStateContextValue}>{children}</AccountStateContext.Provider>
    </AccountHelperContext.Provider>
  )
}
