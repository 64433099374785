import { useCallback, useEffect, useState } from 'react'
import { Alert, Snackbar } from '@mui/material'
import { useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const ALERT_ERROR_QUERY_KEY = 'e'

/**
 * @type {import("react").FunctionComponent}
 */
export const AlertProvider = ({ children }) => {
  const [alertOpen, setAlertOpen] = useState(false)
  const [message, setMessage] = useState(null)

  const { t } = useTranslation()
  const [urlSearchParams, nextSearchParams] = useSearchParams()

  const alertError = urlSearchParams.get(ALERT_ERROR_QUERY_KEY)

  const onAlertClose = useCallback(() => {
    setAlertOpen(false)
    setMessage(null)

    urlSearchParams.delete(ALERT_ERROR_QUERY_KEY)
    nextSearchParams(urlSearchParams)
  }, [nextSearchParams, urlSearchParams])

  useEffect(() => {
    if (alertError && !alertOpen) {
      const messageExists = t(`alerts.${alertError}`)

      if (messageExists && messageExists.length > 0) {
        setAlertOpen(true)
        setMessage(messageExists)
      } else {
        urlSearchParams.delete(ALERT_ERROR_QUERY_KEY)
        nextSearchParams(urlSearchParams)
      }
    }
  }, [alertError, alertOpen, nextSearchParams, t, urlSearchParams])

  return (
    <>
      {children}
      <Snackbar open={alertOpen} onClose={onAlertClose} anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}>
        <Alert onClose={onAlertClose} severity="error" sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>
    </>
  )
}
