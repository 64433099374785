import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook'

import { RouterContext } from '../context'

/**
 * @type {import("react").FunctionComponent}
 */
export const RouterProvider = ({ children }) => {
  const sendDataToGTM = useGTMDispatch()

  const location = useLocation()

  /** @type {[import('../context').RouterContextValue, import('react').Dispatch<import('react').SetStateAction<import('../context').RouterContextValue>>]} */
  const [route, setRoute] = useState({
    to: location,
    from: location
  })

  useEffect(() => {
    setRoute(prev => ({ to: location, from: prev.to }))
  }, [location])

  useEffect(() => {
    const origin = window.location.origin
    const title = document.title
    const pageCategoryLevel1 =
      window.location.pathname === '/' ? 'home' : window.location.pathname.split('/').slice(1)[0]

    setTimeout(
      () =>
        sendDataToGTM({
          errorStatus: '200',
          event: 'virtualPageview',
          virtualPageURL: `${origin}${location.pathname}`,
          virtualPageTitle: title,
          pageCategoryLevel1
        }),
      1000
    )
  }, [location.pathname, sendDataToGTM])

  return <RouterContext.Provider value={route}>{children}</RouterContext.Provider>
}
