import { CircularProgress, Stack } from '@mui/material'

/**
 * @type {import("react").FunctionComponent}
 */
export const AuthLayoutLoader = () => (
  <Stack flexGrow={1} alignItems="center" justifyContent="center" p={8}>
    <CircularProgress />
  </Stack>
)
