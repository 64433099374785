import { HttpLink } from '@apollo/client'
import { onError } from '@apollo/client/link/error'

export const httpLink = new HttpLink({
  uri: process.env.REACT_APP_MIDDLEWARE_URL
})

export const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    const isAuthenticationError = graphQLErrors.some(error => error.extensions.code === 'UNAUTHENTICATED')
    const isNotValidatedError = graphQLErrors.some(error => error.extensions.code === 'UNVALIDATED_HCP_ERROR')

    if (isAuthenticationError) {
      window.location = '/controller/logout'
      return
    }

    if (isNotValidatedError) {
      window.location = '/not-validated'
      return
    }
  }
})
