import { useCallback, useEffect, useRef } from 'react'

/**
 * @template {any[]} T
 * @callback UseDebounceCallback
 * @param {T} args
 * @returns {void}
 */

/**
 * @template T
 * @param {UseDebounceCallback<T>} callback
 * @param {number} wait
 * @returns {UseDebounceCallback<T>}
 */
export function useDebounce(callback, wait) {
  const argsRef = useRef()
  const timeout = useRef()

  const cleanup = useCallback(() => {
    if (timeout.current) {
      clearTimeout(timeout.current)
    }
  }, [])

  useEffect(() => cleanup, [cleanup])

  return useCallback(
    (...args) => {
      argsRef.current = args

      cleanup()

      timeout.current = setTimeout(() => {
        if (argsRef.current) {
          callback(...argsRef.current)
        }
      }, wait)
    },
    [callback, cleanup, wait]
  )
}
