/**
 * @enum {string}
 */
export const SolicitationStatus = {
  Confirmed: 'sent'
}

/**
 * @enum {string}
 */
export const InvitationStatus = {
  Confirmed: 'CONFIRMED',
  Unanswered: 'UNANSWERED',
  Refused: 'REFUSED'
}

/**
 * @enum {string}
 */
export const ParticipationType = {
  Physical: 'PHYSICAL',
  Virtual: 'VIRTUAL'
}

export const PresenceStatus = {
  Absent: 'ABSENT'
}
