import * as Yup from 'yup'

/**
 * @typedef {{
 *  email: string
 * }} ForgotPasswordFormValues
 */

export const ForgotPasswordSchema = Yup.object({
  email: Yup.string()
    .email('auth.forgotPassword.form.inputs.email.errors.email')
    .required('auth.forgotPassword.form.inputs.email.errors.required')
})

/** @type {ForgotPasswordFormValues} */
export const ForgotPasswordDefaultValues = {
  email: ''
}
