import { Box } from '@mui/system'
import { AppBar as MuiAppBar, Button, Stack, Toolbar } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { Logos } from '@hcp/ux'
import { AppRoutes } from '@hcp/router'
import { useAuthHelper } from '@hcp/modules/auth'

/**
 * @typedef {{
 *  displayLogoutButton? : boolean
 * }} AppBarProps
 *
 * @type {import("react").FunctionComponent<AppBarProps>}
 */

export const AppBar = ({ displayLogoutButton = true }) => {
  const { setAuthenticated } = useAuthHelper()

  const { t } = useTranslation()

  return (
    <>
      <MuiAppBar position="absolute" elevation={0} sx={{ px: 2 }}>
        <Toolbar>
          <Stack flexGrow={1} alignItems="center" direction="row">
            <Box sx={{ display: 'flex', alignItems: 'center' }} component={Link} to={AppRoutes.App}>
              <Logos.BM height="30px" width="100%" />
            </Box>
          </Stack>
          {displayLogoutButton && (
            <Button
              variant="outlined"
              color="secondary"
              disableElevation
              sx={{ textTransform: 'uppercase' }}
              onClick={() => setAuthenticated(null)}
            >
              {t('appBar.logout')}
            </Button>
          )}
        </Toolbar>
      </MuiAppBar>
    </>
  )
}
