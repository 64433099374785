import * as Yup from 'yup'

export const PASSWORD_REGEX = new RegExp('^((?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,}))')

/**
 * @typedef {{
 *  email: string
 *  password: string
 * }} RegisterFormValues
 */

export const RegisterSchema = Yup.object({
  email: Yup.string()
    .email('auth.register.form.inputs.email.errors.email')
    .required('auth.register.form.inputs.email.errors.required'),
  password: Yup.string()
    .required('auth.register.form.inputs.password.errors.required')
    .matches(PASSWORD_REGEX, 'auth.register.form.inputs.password.errors.matches')
})

/** @type {RegisterFormValues} */
export const RegisterDefaultValues = {
  email: '',
  password: ''
}
