import { memo } from 'react'
import { useTranslation } from 'react-i18next'

import { FilePreview } from '@hcp/ux'
import { useLvcFilePreview } from '@hcp/modules/files'

/**
 * @typedef {{
 *  event: Gathering
 * }} GatheringFilePreviewProps
 *
 * @type {import('react').NamedExoticComponent<GatheringFilePreviewProps>}
 */
export const LvcFilePreview = memo(({ event }) => {
  const { t } = useTranslation()

  const previewUrl = useLvcFilePreview({ gatheringUuid: event?.gatheringUuid })

  const handleClick = () => {
    window.open(previewUrl, '_blank')
  }

  return <FilePreview label={t('event.tabs.details.documents.lvc')} onClick={handleClick} />
})
