import { useEffect, memo } from 'react'

import { EnvIdMapping } from '../enum'

export const CookieConsentProvider = memo(({ children }) => {
  useEffect(() => {
    const id = EnvIdMapping[process.env.REACT_APP_NODE_ENV]

    if (!id) {
      console.warn(`No ID define to load cookiebot script for env ${process.env.REACT_APP_NODE_ENV}`)
      return
    }

    const script = document.createElement('script')
    script.setAttribute('id', 'Cookiebot')
    script.setAttribute('src', 'https://consent.cookiebot.com/uc.js')
    script.setAttribute('data-cbid', id)
    script.setAttribute('blockingmode', 'auto')
    script.setAttribute('type', 'text/javascript')

    document.querySelector('head').appendChild(script)
  }, [])

  return children
})
