import { createContext } from 'react'
import * as Cookie from 'js-cookie'

export const AUTHENTICATED_TOKEN_KEY = 'atk'
export const USER_TOKEN = 'utk'

/**
 * @returns {string | null}
 */
export function getExistingToken() {
  const token = Cookie.get(AUTHENTICATED_TOKEN_KEY)

  if (token) {
    try {
      return token
    } catch (error) {
      console.warn(error)
    }
  }

  return null
}

/**
 *
 * @returns {string | null}
 */
export function getUserToken() {
  const token = Cookie.get(USER_TOKEN)

  if (token) {
    try {
      return token
    } catch (error) {
      console.warn(error)
    }
  }

  return null
}

export const AUTH_INITIAL_TOKEN = getExistingToken()

/**
 * @param {string | null} token
 * @returns {void}
 */
export function persistToken(token) {
  if (token) {
    Cookie.set(AUTHENTICATED_TOKEN_KEY, token)
  } else {
    Cookie.remove(AUTHENTICATED_TOKEN_KEY)
  }
}

/**
 * @typedef {{
 *  token: string | null
 * }} Account
 */

/**
 * @typedef {{
 *  isAuthenticated: boolean
 * }} AccountStateContextValue
 */

/**
 * @callback SetAuthenticatedFn
 * @param {string} [token]
 * @returns {void}
 */

/**
 * @typedef {{
 *  setAuthenticated: SetAuthenticatedFn
 * }} AccountHelperContextValue
 */

/** @type {import('react').Context<AccountStateContextValue>} */
export const AccountStateContext = createContext({
  isAuthenticated: false
})

/** @type {import('react').Context<AccountHelperContextValue>} */
export const AccountHelperContext = createContext({
  setAuthenticated: async () => undefined
})
