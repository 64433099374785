import * as Cookie from 'js-cookie'
import { useEffect, useRef } from 'react'

const COOKIE_NAME = '_ga_linker_params'
const INTERVAL = 500

/**
 * @param {import('react').MutableRefObject<number>} timerId
 */
const setAdditionalAnalyticsCookie = timerId => {
  if (window.ga?.getAll instanceof Function) {
    const tracker = window.ga.getAll()[0]

    // Allows to work even without AD blockers
    if (!tracker.get('linkerParam')) return

    const queryParam = tracker.get('linkerParam').split('=')
    const linkerParams = {
      [queryParam[0]]: queryParam[1]
    }

    Cookie.set(COOKIE_NAME, JSON.stringify(linkerParams))

    clearInterval(timerId.current)
    console.log('[gan] setted additional required cookie')
  }
}

export const useCrossDomainTracker = () => {
  const timerId = useRef()

  useEffect(() => {
    timerId.current = setInterval(() => {
      setAdditionalAnalyticsCookie(timerId)
    }, INTERVAL)
  }, [])
}
