import { Box } from '@mui/system'
import { Link } from 'react-router-dom'
import { Typography, Container, Grid, Divider } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useTheme } from '@mui/system'

import { Logos } from '@hcp/ux'
import { AppRoutes, ExternalLinks } from '@hcp/router'

/**
 * @type {import('react').FunctionComponent}
 */
export const Footer = () => {
  const { t } = useTranslation()
  const theme = useTheme()

  return (
    <Box bgcolor="primary.dark">
      <Box py={2}>
        <Container>
          <Grid container columnSpacing={3} rowSpacing={1} alignItems="center" justifyContent="center">
            <Grid item>
              <Logos.Biogen height={39} width="100%" />
            </Grid>
            <Grid item>
              <Typography
                component="a"
                target="_blank"
                rel="noreferrer"
                href={ExternalLinks.PrivacyPolicy}
                variant="button1"
                color="grey.500"
              >
                {t('footer.privacyPolicy')}
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                component="a"
                target="_blank"
                rel="noreferrer"
                href={ExternalLinks.TermsOfService}
                variant="button1"
                color="grey.500"
              >
                {t('footer.termsOfService')}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="button1" color="grey.500" component={Link} to={AppRoutes.Cookies} target="_blank">
                {t('footer.cookies')}
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                component="a"
                target="_blank"
                rel="noreferrer"
                href={ExternalLinks.PV}
                variant="button1"
                color="grey.500"
              >
                {t('footer.pV')}
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Divider sx={{ borderColor: theme.palette.grey[500], opacity: 0.2 }} />
      <Box my={1}>
        <Container>
          <Grid container columnSpacing={3} rowSpacing={1} justifyContent="center">
            <Grid item>
              <Typography variant="body3" component="p" textAlign="center">
                {t('footer.operator')}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body3" component="p" textAlign="center">
                {t('footer.hosting')}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body3" component="p" textAlign="center">
                {t('footer.version')}
              </Typography>
            </Grid>
            {/* Vault code */}
            <Grid item>
              <Typography variant="body3" component="p" textAlign="center">
                11/2022-Biogen-166463
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  )
}
