export const AppRoutes = {
  Authentication: {
    Index: '/',
    Login: '/auth/login',
    ForgotPassword: '/auth/forgot-password',
    Register: '/auth/register',
    RegisterFull: '/auth/register-full',
    ResetPassword: '/auth/reset-password',
    Unvalidated: '/not-validated'
  },
  App: '/',
  Events: {
    UpComing: '/',
    MyEvents: '/my-events'
  },
  Cookies: '/cookies',
  Event: {
    Details: '/event/:eventId',
    Transport: '/event/:eventId/transport',
    Hosting: '/event/:eventId/hosting',
    Agenda: '/event/:eventId/agenda',
    ExpenseFee: '/event/:eventId/expense-fee',
    Register: '/event/:eventId/register'
  }
}

export const ExternalLinks = {
  PrivacyPolicy: 'https://www.biogen.fr/fr_FR/privacy-policy.html',
  TermsOfService: 'https://www.biogen.fr/fr_FR/terms-conditions.html',
  PV: 'https://www.biogen.fr/fr_FR/contactus.html'
}
