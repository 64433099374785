import { decode } from 'jsonwebtoken'

import { getExistingToken, getUserToken } from '@hcp/modules/auth/context'

const BIOGEN_EMAIL_SUFFIXES = [
  '@biogen.com',
  '@mailinator.com',
  '@vertic.com',
  '@anthilagency.com',
  '@antwerpes.com',
  '@test.com',
  '@viseven.com'
]

export const useGTMInitialValues = () => {
  const isAuthenticated = Boolean(getExistingToken())
  const token = getUserToken()
  /**
   * @type {{ email: string, status: string, hcpId: string | null } | null}
   */
  const decodedToken = token ? decode(token) : null

  const pageCategoryLevel1 = window.location.pathname === '/' ? 'home' : window.location.pathname.split('/').slice(1)[0]
  const env = process.env.REACT_APP_NODE_ENV === 'production' ? 'prod' : 'dev'

  const values = {
    biib_asset_code: 'other|fr|biogenmoi',
    biib_cookiebot_language: 'fr',
    biib_country: 'fr',
    biib_env: env,
    biib_language: 'fr',
    biib_name: 'biogenmoi',
    biib_region: 'eu',
    biogenEmail: decodedToken
      ? String(BIOGEN_EMAIL_SUFFIXES.some(suffix => (decodedToken?.email ?? '').includes(suffix)))
      : 'false',
    biogenlinc_type: 'other',
    errorStatus: '200',
    pageCategoryLevel1,
    public: 'HCP',
    visitorId: decodedToken?.hcpId ?? 'undefined',
    visitorLoginState: isAuthenticated ? 'logged' : 'anonymous',
    spaWebsite: 'true',
    user_status: decodedToken?.status ?? 'undefined'
  }

  if (!isAuthenticated) {
    delete values.biogenEmail
  }

  return values
}
