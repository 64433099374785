import { Stack } from '@mui/material'
import { Box } from '@mui/system'

import { Footer } from '../Footer/Footer'

/**
 * @typedef {{
 *  translucent: boolean
 * } & import('@mui/system').BoxProps} PageProps
 *
 * @type {import("react").FunctionComponent<PageProps>}
 */
export const Page = ({ children, translucent = true, ...rest }) => {
  return (
    <Stack flexGrow={1} flexDirection="column">
      <Box
        display="flex"
        flexDirection="column"
        flexGrow={1}
        bgcolor="grey.200"
        sx={{ paddingTop: translucent ? 0 : 12 }}
        {...rest}
      >
        {children}
      </Box>

      <Footer />
    </Stack>
  )
}
