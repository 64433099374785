import * as Yup from 'yup'

/**
 * @typedef {{
 *  email: string
 *  password: string
 * }} LoginFormValues
 */

export const LoginSchema = Yup.object({
  email: Yup.string()
    .email('auth.login.form.inputs.email.errors.email')
    .required('auth.login.form.inputs.email.errors.required'),
  password: Yup.string().required('auth.login.form.inputs.password.errors.required')
})

/** @type {LoginFormValues} */
export const LoginDefaultValues = {
  email: '',
  password: ''
}
