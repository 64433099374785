import { useMemo } from 'react'

/**
 * @param {Object} params
 * @param {string} params.gatheringUuid
 * @returns {URL}
 */
export const useLvcFilePreview = ({ gatheringUuid }) =>
  useMemo(() => {
    const origin = window.location.origin
    const url = new URL(`${origin}/controller/healthCareProfessionalFile/${gatheringUuid}/lvc`)

    return url
  }, [gatheringUuid])
