import * as Yup from 'yup'

import { PASSWORD_REGEX } from './register'

/**
 * @typedef {{
 *  password: string
 *  passwordConfirmation: string
 * }} ResetPasswordFormValues
 */

export const ResetPasswordSchema = Yup.object({
  password: Yup.string()
    .required('auth.resetPassword.form.inputs.password.errors.required')
    .oneOf([Yup.ref('passwordConfirmation')], 'auth.resetPassword.form.inputs.password.errors.oneOf')
    .matches(PASSWORD_REGEX, 'auth.resetPassword.form.inputs.password.errors.matches'),
  passwordConfirmation: Yup.string()
    .oneOf([Yup.ref('password')], '')
    .required('auth.resetPassword.form.inputs.passwordConfirmation.errors.required')
})

/** @type {ResetPasswordFormValues} */
export const ResetPasswordDefaultValues = {
  password: '',
  passwordConfirmation: ''
}
