import { useMemo } from 'react'

/**
 * @param {Object} params
 * @param {string} params.gatheringUuid
 * @param {string} params.gatheringFileUuid
 * @returns {URL}
 */
export const usePreviewGatheringFile = ({ gatheringUuid, gatheringFileUuid }) =>
  useMemo(() => {
    const origin = window.location.origin
    const url = new URL(`${origin}/controller/gatheringFile/${gatheringUuid}/${gatheringFileUuid}`)

    return url
  }, [gatheringUuid, gatheringFileUuid])
