import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useTranslationContext } from '.'

export const useTranslationWithContext = () => {
  const translationContext = useTranslationContext()
  const { t, ...rest } = useTranslation()

  const _t = useCallback((key, ...rest) => t(`${translationContext}.${key}`, ...rest), [t, translationContext])

  return useMemo(() => ({ ...rest, t: _t }), [_t, rest])
}
