import { memo } from 'react'

import { FilePreview } from '@hcp/ux'
import { usePreviewHealthCareProfessionalFile } from '@hcp/modules/files'

/**
 * @typedef {{
 *  event: Gathering
 *  hcpFile: HealthCareProfessionalFile
 * }} GatheringFilePreviewProps
 *
 * @type {import('react').NamedExoticComponent<GatheringFilePreviewProps>}
 */
export const HcpFilePreview = memo(({ event, hcpFile }) => {
  const previewUrl = usePreviewHealthCareProfessionalFile({
    gatheringUuid: event?.gatheringUuid,
    healthCareProfessionalFileUuid: hcpFile?.healthCareProfessionalFileUuid
  })

  const handleClick = () => {
    window.open(previewUrl, '_blank')
  }

  return <FilePreview label={hcpFile.name} onClick={handleClick} />
})
