import { useEffect } from 'react'
import { ThemeProvider } from '@mui/material/styles'
import { CssBaseline } from '@mui/material'
import { BrowserRouter } from 'react-router-dom'
import { GTMProvider, useGTMDispatch } from '@elgorditosalsero/react-gtm-hook'
import 'tailwindcss/tailwind.css'

import { CookieConsentProvider } from '@hcp/modules/cookies'
import { GraphQLProvider } from '@hcp/graphql'
import { I18nProvider } from '@hcp/i18n'
import { AuthProvider } from '@hcp/modules/auth'
import { AppRouter, RouterProvider, ScrollTopRestauration } from '@hcp/router'
import { TranslationContextProvider } from '@hcp/modules/translations'
import { AlertProvider } from '@hcp/modules/system'
import { HttpProvider } from '@hcp/http'
import { IdentityProvider } from '@hcp/modules/user'
import { theme } from '@hcp/ux'
import { useGTMInitialValues, useCrossDomainTracker } from '@hcp/core'

const InitialGTMValues = () => {
  const sendDataToGTM = useGTMDispatch()
  const gtmInitialValues = useGTMInitialValues()

  useEffect(
    () => sendDataToGTM({ ...gtmInitialValues }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  useCrossDomainTracker()

  return null
}

const GTM_PARAMS = { id: 'GTM-5HW3S27', injectScript: false }

export const App = () => (
  <CookieConsentProvider>
    <GTMProvider state={GTM_PARAMS}>
      <InitialGTMValues />
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <I18nProvider>
          <HttpProvider>
            <GraphQLProvider>
              <TranslationContextProvider>
                <BrowserRouter>
                  <ScrollTopRestauration />
                  <RouterProvider>
                    <AlertProvider>
                      <AuthProvider>
                        <IdentityProvider>
                          <AppRouter />
                        </IdentityProvider>
                      </AuthProvider>
                    </AlertProvider>
                  </RouterProvider>
                </BrowserRouter>
              </TranslationContextProvider>
            </GraphQLProvider>
          </HttpProvider>
        </I18nProvider>
      </ThemeProvider>
    </GTMProvider>
  </CookieConsentProvider>
)
