import { loader } from 'graphql.macro'
import { useQuery } from '@apollo/client'

const QUERY = loader('../../../graphql/participants/queries/currentHealthCareProfessional.gql')

/**
 * @typedef {{ currentHealthCareProfessional: HealthCareProfessional }} QueryResult
 * @typedef {{}} QueryVar
 *
 * @param {import('@apollo/client').QueryHookOptions} options
 *
 * @returns {import('@apollo/client').QueryResult<QueryResult, QueryVar>}
 */
export const useCurrentHcp = options => useQuery(QUERY, options)
