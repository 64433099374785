import { useCallback } from 'react'

/**
 * @callback DownloadFileFunction
 * @returns {void}
 */

/**
 * @param {string} url
 * @returns {DownloadFileFunction}
 */
export const useDownloadFile = url =>
  useCallback(() => {
    const anchor = document.createElement('a')
    anchor.setAttribute('href', url)
    anchor.setAttribute('download', true)
    anchor.click()
  }, [url])
