import { useReducer } from 'react'

/**
 * @param {boolean} state
 * @param {any} [nextValue]
 * @returns {boolean}
 */
function toggleReducer(state, nextValue) {
  return typeof nextValue === 'boolean' ? nextValue : !state
}

/**
 * @param {boolean} initialValue
 * @returns {[boolean, import('react').Dispatch<any>]}
 */
export function useToggle(initialValue) {
  return useReducer(toggleReducer, initialValue)
}
