import React, { useState } from 'react'

import { TranslationContextContext } from '../context'

/**
 * @type {import('react').FunctionComponent}
 */
export const TranslationContextProvider = ({ children }) => {
  const [translationContext, setTranslationContext] = useState(null)

  return (
    <TranslationContextContext.Provider value={[translationContext, setTranslationContext]}>
      {children}
    </TranslationContextContext.Provider>
  )
}
