import { useContext, useEffect } from 'react'
import { UNSAFE_NavigationContext } from 'react-router-dom'

/**
 * @param {import('history').Blocker} blocker
 * @param {boolean} when
 */
export const useBlocker = (blocker, when) => {
  /** @type {import('history').History} */
  const navigator = useContext(UNSAFE_NavigationContext).navigator

  useEffect(() => {
    if (!when) return

    const unblock = navigator.block(tx => {
      const autoUnblockingTx = {
        ...tx,
        retry() {
          unblock()
          tx.retry()
        }
      }

      blocker(autoUnblockingTx)
    })

    return unblock
  }, [navigator, blocker, when])
}
