import * as Yup from 'yup'

export const PASSWORD_REGEX = new RegExp('^((?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,}))')

export const HCP_IDENTIFIER_REGEX = new RegExp('(^[0-9]{11}$)|(^[0-9]{9}$)')

export const POSTAL_CODE_REGEX = new RegExp('^[0-9]{5}$')

/**
 * @typedef {{
 *  password: string
 *  firstname: string
 *  lastname: string
 *  rpps: string
 *  adeli: string
 * }} RegisterFullFormValues
 */

export const RegisterFullSchema = Yup.object({
  password: Yup.string()
    .required('auth.registerFull.form.inputs.password.errors.required')
    .matches(PASSWORD_REGEX, 'auth.registerFull.form.inputs.password.errors.matches'),
  firstname: Yup.string().required('auth.registerFull.form.inputs.firstname.errors.required'),
  lastname: Yup.string().required('auth.registerFull.form.inputs.lastname.errors.required'),
  hcpIdentifier: Yup.string()
    .required('auth.registerFull.form.inputs.hcpIdentifier.errors.required')
    .matches(HCP_IDENTIFIER_REGEX, 'auth.registerFull.form.inputs.hcpIdentifier.errors.matches'),
  postalCode: Yup.string().matches(POSTAL_CODE_REGEX, 'auth.registerFull.form.inputs.postalCode.errors.matches')
})

/** @type {RegisterFormValues} */
export const RegisterFullDefaultValues = {
  password: '',
  firstname: '',
  lastname: '',
  postalCode: '',
  hcpIdentifier: ''
}
