import { Button, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { useTranslation } from 'react-i18next'

import { Artworks } from '@hcp/ux'

import { SimpleDialog } from './SimpleDialog'

/**
 * @typedef {{ open: boolean, onClose: Function }} ConfirmationDialogProps
 *
 * @type {import('react').NamedExoticComponent<ConfirmationDialogProps>}
 */
export const ConfirmationDialog = ({ open, onClose }) => {
  const { t } = useTranslation()

  return (
    <SimpleDialog
      open={open}
      title={t('ux.confirmationDialog.title')}
      content={
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
          <Artworks.Confirmation width={176} height="100%" />
          <Typography mt={4}>{t('ux.confirmationDialog.text')}</Typography>
        </Box>
      }
      actions={[
        <Button color="secondary" variant="outlined" onClick={onClose}>
          {t('ux.confirmationDialog.button')}
        </Button>
      ]}
    />
  )
}
