/**
 * @class
 * @constructor
 * @public
 */
export class BaseError extends Error {
  /**
   * @param {string} [message]
   * @param {Error} [originalError]
   */
  constructor(message, originalError) {
    super(message)

    /**
     * @type {Error | undefined}
     * @public
     */
    this.originalError = originalError
  }
}

/**
 * @class
 * @constructor
 * @public
 */
export class NetworkError extends BaseError {
  /**
   * @param {Error} [originalError]
   */
  constructor(originalError) {
    super('A network error ocurred', originalError)
  }
}

/**
 * @class
 * @constructor
 * @public
 */
export class ServerError extends BaseError {
  /**
   * @param {Error} [originalError]
   */
  constructor(originalError) {
    super('A server error ocurred', originalError)
  }
}
