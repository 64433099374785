import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'

import fr from './translations/fr.json'

i18next.use(initReactI18next).init({
  lng: 'fr',
  resources: {
    fr: {
      translation: fr
    }
  }
})

export { i18next }
