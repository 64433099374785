/**
 * @typedef {{
 *  index: number
 *  title: string
 *  description?: string
 * }} FormCardSectionProps
 */

import { Card, CardContent, Grid, Typography, useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/material/styles'

/**
 * @type {import("react").FunctionComponent<FormCardSectionProps>}
 */
export const FormCardSection = ({ index, title, description, children }) => {
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up('md'))

  return (
    <Card elevation={0} sx={{ mb: 4, borderRadius: matches ? 1 : 0 }}>
      <CardContent sx={{ paddingX: matches ? 7 : 5, paddingTop: matches ? 6 : 4, ':last-child': { paddingBottom: 6 } }}>
        <Grid container columnSpacing={2}>
          <Grid item xs={12} md={4} marginBottom={[3, 3, 0]} paddingRight={[0, 0, 2]}>
            <Typography variant="h3" component="p" color="secondary">
              {index.toString().padStart(2, '0')}
            </Typography>
            <Typography variant="h3" color="primary">
              {title}
            </Typography>
            {description && (
              <Typography
                variant="body1"
                mt={2}
                whiteSpace="pre-line"
                dangerouslySetInnerHTML={{ __html: description }}
              />
            )}
          </Grid>
          <Grid item xs={12} md={8}>
            {children}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}
