import { useMutation } from '@apollo/client'
import { loader } from 'graphql.macro'

const MUTATION = loader('../../../graphql/participants/mutations/askUnsubscribeFromGathering.gql')

/**
 * @typedef {{ gatheringUuid: string }} MutationVars
 *
 * @param {string} gatheringUuid
 * @returns {import('@apollo/client').MutationTuple<any, MutationVars>}
 */
export const useAskUnsubscribeFromGathering = gatheringUuid =>
  useMutation(MUTATION, {
    variables: { gatheringUuid }
  })
