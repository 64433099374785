import { createContext } from 'react'

/**
 * @typedef {{
 *  firstname?: string
 *  lastname?: string,
 *  email?: string
 * }} IdentityContextValue
 */

/**
 * @type {import('react').Context<IdentityContextValue>}
 */
export const IdentityContext = createContext(null)
