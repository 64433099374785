import startCase from 'lodash.startcase'
import { format } from 'date-fns'
import fr from 'date-fns/locale/fr'

/**
 * @param {string | Date} date
 * @param {string} customFormat
 */

export const formatDate = (date, customFormat) => {
  if (!date || !customFormat) return ''
  const formatDate = format(new Date(date), customFormat, {
    locale: fr
  })
  return startCase(formatDate)
}
