import { alpha, createTheme, darken } from '@mui/material/styles'

const defaultTheme = createTheme({
  typography: {
    h1: {
      fontFamily: 'Franklin Gothic Medium',
      fontWeight: 500,
      fontStyle: 'normal',
      fontSize: 32,
      color: '#153950',
      lineHeight: '35.07px'
    },
    h2: {
      fontFamily: 'Franklin Gothic Medium',
      fontWeight: 500,
      fontStyle: 'normal',
      fontSize: 23,
      color: '#373A58',
      lineHeight: '26.13px'
    },
    h3: {
      fontFamily: 'Franklin Gothic Medium',
      fontWeight: 500,
      fontStyle: 'normal',
      fontSize: 20,
      color: '#373A58',
      lineHeight: '22.68px'
    },
    h4: {
      fontFamily: 'Franklin Gothic Medium',
      fontWeight: 500,
      fontStyle: 'normal',
      fontSize: 18,
      color: '#373A58',
      lineHeight: '20.41px'
    },
    body1: {
      fontWeight: 400,
      fontStyle: 'normal',
      fontSize: 14,
      color: '#373A58',
      lineHeight: '18.2px'
    },
    body2: {
      fontWeight: 400,
      fontStyle: 'normal',
      fontSize: 14,
      color: '#5E666F',
      lineHeight: '24.1px'
    },
    body3: {
      fontWeight: 300,
      fontStyle: 'normal',
      fontSize: 12,
      color: '#D1D3D3',
      lineHeight: '14.06px'
    }
  },
  palette: {
    primary: {
      main: '#3C72B5',
      200: alpha('#3C72B5', 0.2),
      light: '#83AEDF',
      light200: alpha('#83AEDF', 0.2),
      dark: '#153950',
      greyish: '#A5A9AD',
      greyish200: alpha('#A5A9AD', 0.2)
    },
    secondary: { main: '#B2D16F', 200: alpha('#B2D16F', 0.2), contrastText: '#FFF', light: '#EDF3DE' },
    warning: { main: '#EB9D3F' },
    grey: {
      main: '#7E878D',
      500: '#D1D3D3',
      200: alpha('#D1D3D3', 0.2),
      light: '#C4C4C4',
      alt: '#5E666F'
    },
    blue: { main: '#3C72B5', 200: alpha('#3C72B5', 0.2), greyish: '#373A58', contrastColor: '#FFF' },
    error: { main: '#922A27', alt: '#F44336', contrastText: '#FFF' },
    white: { main: '#FFFFFF', white50: 'rgba(255, 255, 255, 0.5)' },
    event: { congress: '#78B061', biogenEvent: '#8AA6D0' },
    activity: { stand: '#79DAA0', workshop: '#6987D7', symposium: '#65B7DA', diner: '#F0827F', meeting: '#A5A9AD' }
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Franklin Gothic Medium';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: url(/assets/fonts/Franklin_Gothic_Medium.ttf) format('truetype');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
      `
    },
    MuiAppBar: {
      defaultProps: {
        color: 'transparent'
      }
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true
      },
      styleOverrides: {
        root: {
          fontFamily: 'Franklin Gothic Medium',
          fontSize: 14,
          lineHeight: '24px',
          letterSpacing: '0.4px',
          textTransform: 'none'
        },
        contained: {
          borderRadius: 3
        },
        containedPrimary: {
          color: '#FFFFFF',
          backgroundColor: '#B2D16F',
          '&:hover': {
            backgroundColor: darken('#B2D16F', 0.2)
          }
        },
        containedSecondary: {
          color: '#FFFFFF',
          backgroundColor: '#3C72B5',
          '&:hover': {
            backgroundColor: darken('#3C72B5', 0.2)
          }
        },
        outlined: {
          borderRadius: 3
        },
        outlinedPrimary: {
          color: '#B2D16F',
          borderColor: '#B2D16F',
          backgroundColor: '#FFFFFF',
          '&:hover': {
            color: darken('#B2D16F', 0.2),
            borderColor: darken('#B2D16F', 0.2),
            backgroundColor: darken('#FFFFFF', 0.025)
          }
        },
        outlinedSecondary: {
          color: '#3C72B5',
          borderColor: '#3C72B5',
          backgroundColor: '#FFFFFF',
          '&:hover': {
            color: darken('#3C72B5', 0.2),
            borderColor: darken('#3C72B5', 0.2),
            backgroundColor: darken('#FFFFFF', 0.025)
          }
        },
        textPrimary: {
          color: '#B2D16F',
          '&:hover': {
            color: darken('#B2D16F', 0.2),
            backgroundColor: darken('#FFFFFF', 0.025)
          }
        },
        textSecondary: {
          color: '#3C72B5',
          '&:hover': {
            color: darken('#3C72B5', 0.2),
            backgroundColor: darken('#FFFFFF', 0.025)
          }
        }
      }
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: 5
        }
      }
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: 24
        }
      }
    },
    MuiChip: {
      styleOverrides: {
        iconSmall: {
          marginRight: 2
        },
        labelSmall: {
          fontWeight: 500,
          fontSize: 13,
          lineHeight: '18px'
        },
        labelMedium: {
          fontWeight: 600,
          fontSize: 13,
          lineHeight: '18px'
        }
      },
      variants: [
        {
          props: { variant: 'tag' },
          style: {
            fontSize: '13px',
            fontWeight: 500,
            fontStyle: 'normal',
            lineHeight: '15.23px',
            height: '24px',
            // Reduce padding if there is an icon
            '& svg + .MuiChip-label': {
              paddingLeft: '8px'
            }
          }
        },
        {
          props: { variant: 'tag', color: 'primary' },
          style: {
            backgroundColor: alpha('#3C72B5', 0.2), // primary.200
            color: '#3C72B5' // blue.main
          }
        },
        {
          props: { variant: 'tag', color: 'secondary' },
          style: {
            backgroundColor: alpha('#B2D16F', 0.2), // secondary.200
            color: '#62944D' // secondary.dark
          }
        },
        {
          props: { variant: 'tagOutlined' },
          style: {
            fontSize: '13px',
            fontWeight: 500,
            fontStyle: 'normal',
            lineHeight: '15.23px',
            color: '#153950', // primary.dark
            height: '24px',
            padding: '4px',
            backgroundColor: 'transparent',
            borderColor: '#153950', // primary.dark
            borderWidth: '1px',
            borderStyle: 'solid'
          }
        }
      ]
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            color: 'rgba(21, 57, 80, 0.4)'
          }
        }
      }
    },
    MuiDialog: {
      variants: [
        {
          props: { variant: 'form' },
          style: {
            '.MuiPaper-root': {
              padding: '48px 40px'
            }
          }
        }
      ],
      styleOverrides: {
        root: {
          '.MuiPaper-root': {
            padding: '72px 40px',
            position: 'relative'
          },
          '.MuiDialogContent-root': {
            color: '#5E666F' // grey.alt
          },
          '.MuiDialogActions-root .MuiButton-root': {
            minWidth: '160px'
          }
        }
      }
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontFamily: 'Franklin Gothic Medium',
          fontWeight: 500,
          fontStyle: 'normal',
          fontSize: 23,
          lineHeight: '26.08px',
          textAlign: 'center'
        }
      }
    },
    MuiDialogContentText: {
      styleOverrides: {
        root: {
          marginTop: 32,
          textAlign: 'center'
        }
      }
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          marginTop: 40,
          justifyContent: 'center',
          '& .MuiButton-root': {
            paddingLeft: '36px',
            paddingRight: '36px',
            '&:not(:last-of-type)': {
              marginRight: 12
            }
          }
        }
      }
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          '& > .MuiFormLabel-root:not(.MuiInputLabel-root)': {
            fontWeight: 500,
            fontSize: 14,
            lineHeight: '16.41px',
            letterSpacing: 0.15,
            marginBottom: 16,
            color: '#153950'
          }
        }
      }
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            color: 'rgba(21, 57, 80, 0.4)'
          }
        }
      }
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          '&.Mui-focused': {
            color: 'inherit'
          }
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        inputSizeSmall: {
          fontSize: '0.875rem',
          lineHeight: '1.5rem',
          height: '1.5rem'
        },
        input: {
          '::placeholder': {
            color: '#7E878D',
            fontStyle: 'italic',
            opacity: 1
          }
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontWeight: 700,
          fontSize: 15,
          lineHeight: '17.58px',
          textTransform: 'none',
          marginLeft: '24px !important',
          marginRight: '24px !important'
        }
      }
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          fontFamily: 'Franklin Gothic Medium',
          fontWeight: 500,
          fontSize: 15,
          lineHeight: '26px',
          letterSpacing: 0.46,
          padding: '8px 24px',
          color: 'inherit',
          '&.Mui-selected': {
            color: '#3C72B5',
            backgroundColor: 'transparent',
            border: '1px solid #3C72B5 !important'
          }
        }
      }
    },
    MuiToggleButtonGroup: {
      styleOverrides: {
        root: {
          color: '#D1D3D3',
          '& + .MuiFormHelperText-root': {
            marginLeft: 0
          }
        },
        grouped: {
          border: '1px solid #D1D3D3',
          borderRadius: '4px !important'
        },
        groupedHorizontal: {
          marginLeft: 8,
          marginRight: 8,
          ':first-of-type': {
            marginLeft: 0
          },
          ':last-of-type': {
            marginRight: 0
          },
          ':not(:first-of-type)': {
            marginLeft: 8,
            borderLeft: '1px solid #D1D3D3'
          }
        },
        groupedVertical: {
          marginTop: 8,
          marginBottom: 8,
          ':first-of-type': {
            marginTop: 0
          },
          ':last-of-type': {
            marginBottom: 0
          },
          ':not(:first-of-type)': {
            marginTop: 8,
            borderTop: '1px solid #D1D3D3'
          }
        }
      }
    },
    MuiTypography: {
      variants: [
        {
          props: { variant: 'button1' },
          style: {
            fontFamily: 'Franklin Gothic Medium',
            fontWeight: 500,
            fontStyle: 'normal',
            fontSize: 14,
            color: '#373A58',
            lineHeight: '24px',
            letterSpacing: 0.4
          }
        },
        {
          props: { variant: 'cardTitle' },
          style: {
            fontFamily: 'Franklin Gothic Medium',
            fontWeight: 500,
            fontStyle: 'normal',
            fontSize: 18,
            color: '#153950',
            lineHeight: '20.41px'
          }
        },
        {
          props: { variant: 'cardLabel' },
          style: {
            fontWeight: 500,
            fontStyle: 'normal',
            fontSize: 14,
            color: '#3C72B5',
            lineHeight: '16.41px'
          }
        },
        {
          props: { variant: 'listLabel' },
          style: {
            fontWeight: 500,
            fontStyle: 'normal',
            fontSize: 14,
            color: '#373A58',
            lineHeight: '16.41px'
          }
        },
        {
          props: { variant: 'timelineSmall' },
          style: {
            fontWeight: 700,
            fontStyle: 'normal',
            fontSize: 12,
            color: '#373A58',
            lineHeight: '14.06px'
          }
        },
        {
          props: { variant: 'timelineBig' },
          style: {
            fontWeight: 700,
            fontStyle: 'normal',
            fontSize: 14,
            color: '#373A58',
            lineHeight: '14.06px'
          }
        },
        {
          props: { variant: 'smallBold' },
          style: {
            fontWeight: 700,
            fontStyle: 'normal',
            fontSize: 12,
            color: '#153950',
            lineHeight: '14.06px'
          }
        },
        {
          props: { variant: 'timelineHighlight' },
          style: {
            fontWeight: 700,
            fontStyle: 'normal',
            fontSize: 16,
            lineHeight: '14.18px'
          }
        }
      ],
      styleOverrides: {
        subtitle2: {
          fontWeight: 800,
          fontSize: 21,
          lineHeight: '27.32px'
        },
        chip: {
          fontWeight: 600,
          fontSize: 13,
          lineHeight: '18px'
        }
      }
    }
  }
})

const { breakpoints } = defaultTheme

defaultTheme.typography.h1[breakpoints.between('xs', 'sm')] = {
  fontSize: 22,
  lineHeight: '22.9px'
}

defaultTheme.typography.h2[breakpoints.between('xs', 'sm')] = {
  fontSize: 20,
  lineHeight: '22.72px'
}

/** @type {import('@mui/material').Theme} */
export const theme = defaultTheme
