import { I18nextProvider } from 'react-i18next'
import { LocalizationProvider } from '@mui/lab'
import DateAdapter from '@mui/lab/AdapterDateFns'
import frLocale from 'date-fns/locale/fr'

import { LocaleContext } from '../context'
import { i18next } from '../client'

/**
 * @type {import('react').FunctionComponent}
 */
export const I18nProvider = ({ children }) => (
  <LocaleContext.Provider value={frLocale}>
    <LocalizationProvider dateAdapter={DateAdapter} locale={frLocale}>
      <I18nextProvider i18n={i18next}>{children}</I18nextProvider>
    </LocalizationProvider>
  </LocaleContext.Provider>
)
