import { Children, cloneElement, isValidElement } from 'react'
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { Box } from '@mui/system'

/**
 * @typedef {{
 *  open?: boolean
 *  title?: string
 *  image?: string | import('react').ReactNode
 *  icon?: import('react').ReactNode
 *  content?: string | string[] | import('react').ReactNode
 *  actions?: import('react').ReactNode[]
 *  onClose?: Function
 * } & import('@mui/material').DialogProps} SimpleDialogProps
 *
 * @type {import('react').FunctionComponent<SimpleDialogProps>}
 */
export const SimpleDialog = ({ open = false, image, icon, title, content, actions = [], onClose, ...props }) => (
  <Dialog
    open={open}
    PaperProps={{
      sx: { justifyContent: 'center' }
    }}
    maxWidth="sm"
    {...props}
  >
    {onClose && (
      <Box display="flex" justifyContent="flex-end" position="absolute" top={0} left={0} right={0} p={3}>
        <CloseIcon sx={{ cursor: 'pointer' }} onClick={onClose} />
      </Box>
    )}
    {title && <DialogTitle>{title}</DialogTitle>}
    {icon && <Box sx={{ display: 'flex', justifyContent: 'center' }}>{icon}</Box>}
    {image && isValidElement(image) ? (
      cloneElement(image)
    ) : (
      <Box display="block" component="img" marginX="auto" loading="lazy" maxHeight={192} src={image} />
    )}
    {content && (
      <DialogContent sx={{ padding: 0, flexGrow: 0 }}>
        {isValidElement(content) ? (
          cloneElement(content)
        ) : typeof content === 'string' ? (
          <DialogContentText whiteSpace="pre-line">{content}</DialogContentText>
        ) : Array.isArray(content) ? (
          content.map(c =>
            typeof c === 'string' ? (
              <DialogContentText
                key={c}
                whiteSpace="pre-line"
                dangerouslySetInnerHTML={{ __html: c }}
                marginBottom={2}
              />
            ) : isValidElement(c) ? (
              cloneElement(c)
            ) : null
          )
        ) : (
          content
        )}
      </DialogContent>
    )}
    {actions.length > 0 && (
      <DialogActions disableSpacing sx={{ padding: 0 }}>
        {Children.toArray(actions)}
      </DialogActions>
    )}
  </Dialog>
)
