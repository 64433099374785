import { createContext } from 'react'

/**
 * @typedef {{
 *  to: import("react-router-dom").Location
 *  from: import("react-router-dom").Location
 * }} RouterContextValue
 */

/** @type {import("react").Context<RouterContextValue>} */
export const RouterContext = createContext({ to: null, from: null })
